import axios from "axios";

const getEventsApi = process.env.REACT_APP_API_URL + "/api/get-events-list?myEvents=true";

const fetchEvents = async () => {
    try {
        const response = await axios.get(getEventsApi);
        return response.data;
    } catch (error) {
        console.error("Error fetching Events:", error);
        throw error;
    }
};

export { fetchEvents };
