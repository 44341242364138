import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { fetchUser } from '../../apis/UserAPI';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export default function ManageProfile() {
  const [user, setUser] = useState({});
  const [isEditing, setIsEditing] = useState(false); // State to track if in edit mode
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const authUser = useAuthUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUser(authUser.uid);
        setUser(userData[0]);
        setIsLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error, show error message, etc.
      }
    };
    fetchData();
  }, [authUser.uid]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <div className="row">
      <div className="column">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ maxWidth: '300px' }}>
              {isLoading ? ( // Render skeleton if loading
                <>
                  <Skeleton variant="text" width={200} height={40} animation="wave" />
                  <Skeleton variant="text" width={150} height={30} animation="wave" />
                </>
              ) : (
                <>
                  <Typography variant="h4">
                    {isEditing ? '' : user.name}
                  </Typography>
                  <Typography variant="h6" color={"gray"} noWrap>
                    {isEditing ? '' : user.contact}
                  </Typography>
                </>
              )}
            </div>
          </Grid>
          {!isEditing && (
            <Grid item xs={12}>
              <Button variant='outlined' onClick={handleEditClick}>
                Edit Profile
              </Button>
            </Grid>
          )}
          {isEditing && ( // Render text fields only if in edit mode
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  id="Name"
                  name="Name"
                  value={user.name || ''}
                  label="Name"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="Contact"
                  name="Contact"
                  value={user.contact || ''}
                  label="Contact"
                  fullWidth
                  autoComplete="email"
                  variant="standard"
                />
              </Grid>
              <Grid container item xs={12} spacing={1}> {/* Add container and spacing */}
                <Grid item> {/* Grid item for the Save button */}
                  <Button variant='contained' onClick={handleEditClick}>
                    Save
                  </Button>
                </Grid>
                <Grid item> {/* Grid item for the Cancel button */}
                  <Button variant='outlined' onClick={handleCancelClick}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className="column"></div>
    </div>
  );
}
