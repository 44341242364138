import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { fetchEvents } from "../../apis/EventsAPI";
import { Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

function EventsList() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchEvents();
                setEvents(data);
            } catch (error) {
                // Handle error, show error message, etc.
            }
        };
        fetchData();
    }, []);

    const formatTime = (datetimeString) => {
        const date = new Date(datetimeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Upcoming Events
            </Typography>
            {events.map(event => (
                <Paper key={event._id}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {event.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {formatTime(event.start)} to {formatTime(event.end)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Room: {event.roomName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Email: {event.email}
                        </Typography>
                    </CardContent>
                </Paper>
            ))}
        </div>
    );
}

export default EventsList;
