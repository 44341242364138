import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { Link } from "react-router-dom";
import { Menu, IconButton } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const logoStyle = {
  padding: "10px 10px 10px 20px",
  width: "90px",
  height: "auto",
  cursor: "pointer",
};

function AppAppBar({ mode, toggleColorMode }) {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const signOutApi = process.env.REACT_APP_API_URL + "/api/signout";
  const signOut = useSignOut();
  const navigate = useNavigate();

  const signOutAction = () => {
    signOut();
    try {
      axios.get(signOutApi);
    } catch (error) {
      console.error("Error Logging out:", error);
      // Handle error, show error message, etc.
    }
    navigate("/");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const settings = [{ label: "Logout", link: "/" }
  //,{ label: "Manage Profile", link: "/profile" }
  ];

  // UNUSED CODE
  // const scrollToSection = (sectionId) => {
  //   const sectionElement = document.getElementById(sectionId);
  //   const offset = 128;
  //   if (sectionElement) {
  //     const targetScroll = sectionElement.offsetTop - offset;
  //     sectionElement.scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo({
  //       top: targetScroll,
  //       behavior: 'smooth',
  //     });
  //     setOpen(false);
  //   }
  // };

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        {/* FOR DESKTOP */}
        <Container maxWidth='lg'>
          <Toolbar
            variant='regular'
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(203, 224, 15, 0.1), 1px 1.5px 2px -1px rgba(203, 224, 15, 0.15), 4px 4px 12px -2.5px rgba(203, 224, 15, 0.15)`
                  : "0 0 1px rgba(203, 224, 15, 0.1), 1px 1.5px 2px -1px rgba(203, 224, 15, 0.1), 1px 1.5px 2px -2.5px rgba(203, 224, 15, 0.1)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <Link to='/'>
                <img
                  src='/static/images/image-list/mp-logo.png'
                  style={logoStyle}
                  alt='logo of Millennial Pod'
                />
              </Link>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  component={Link}
                  to='/plans'
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    Plans
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/locations'
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    Locations
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/contact-us'
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    Contact us
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/about'
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    About
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/rooms'
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    Rooms
                  </Typography>
                </MenuItem>
                <RequireAuth>
                  {authUser?.typeId >= 3 && (
                    <Button
                      sx={{ py: "6px", px: "12px" }}
                      variant='outlined'
                      size='small'
                      component='a'
                      href='/dashboard'
                      color='primary'
                    >
                      Portal
                    </Button>
                  )}
                </RequireAuth>
              </Box>
            </Box>
            {/* Show if user is signed in */}
            {isAuthenticated() ? (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography
                  variant='body2'
                  color='text.primary'
                  paddingRight={2}
                >
                  Hi, {authUser.name}!
                </Typography>
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <AccountCircleOutlinedIcon />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.label}
                      onClick={() => {
                        if (setting.label === "Logout") {
                          signOutAction();
                        } else {
                          handleCloseUserMenu();
                          navigate(setting.link);
                        }
                      }}
                      component={Link}
                      to={setting.link}
                    >
                      <Typography textAlign='center'>
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              // Show if signed out
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <ToggleColorMode
                  mode={mode}
                  toggleColorMode={toggleColorMode}
                />
                <Button
                  color='primary'
                  variant='outlined'
                  size='small'
                  component='a'
                  href='/sign-in'
                >
                  Sign in
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  size='small'
                  component='a'
                  href='/sign-up'
                >
                  Sign up
                </Button>
              </Box>
            )}
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant='text'
                color='primary'
                aria-label='menu'
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
              >
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  {/* FOR PHONE */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode
                      mode={mode}
                      toggleColorMode={toggleColorMode}
                    />
                  </Box>
                  <MenuItem
                    component={Link}
                    to='/plans'
                  >
                    Plans
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/locations'
                  >
                    Locations
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/contact-us'
                  >
                    Contact us
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/about'
                  >
                    About
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='/rooms'
                  >
                    Rooms
                  </MenuItem>
                  {/* Only visible when logged in */}
                  <Divider />
                  <RequireAuth>
                    {authUser?.typeId >= 3 && (
                      <Button
                        color='primary'
                        variant='outlined'
                        component='a'
                        href='/dashboard'
                        sx={{ width: "100%", mb: 1 }}
                      >
                        Portal
                      </Button>
                    )}
                  </RequireAuth>
                  {isAuthenticated() ? (
                    <Box>
                        <Button
                          disabled='true'
                          color='primary'
                          variant='outlined'
                          component='a'
                          href='/profile'
                          sx={{ width: "100%" }}
                        >
                          Manage Profile
                        </Button>
                      <Button
                        color='primary'
                        variant='text'
                        component='a'
                        onClick={signOutAction}
                        sx={{ width: "100%" }}
                      >
                        Logout
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='contained'
                          component='a'
                          href='/sign-up'
                          sx={{ width: "100%" }}
                        >
                          Sign up
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='outlined'
                          component='a'
                          href='/sign-in'
                          sx={{ width: "100%" }}
                        >
                          Sign in
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
